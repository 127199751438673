// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
}

.masthead-title {
  margin-top: 0;
  margin-bottom: 0;
  color: $gray-5;

  a {
    color: inherit;
  }

  small {
    font-size: 75%;
    font-weight: 400;
    opacity: .5;
  }
}
